import "core-js/modules/es.function.name.js";
//
//
//
//
//
//
//
//
//
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import PermissionList from "@/view/pages/profile/UserPermissionList";
export default {
  name: "UserPermission",
  data: function data() {
    return {
      role_id: 0,
      role_name: null,
      permissionDetail: new Object()
    };
  },
  components: {
    ListingTemplate: ListingTemplate,
    PermissionList: PermissionList
  },
  mounted: function mounted() {
    this.role_id = this.$route.params.id;
    this.role_name = this.$route.query.name;
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: "Permission"
    }]);
  }
};