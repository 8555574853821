//
//
//
import { GET } from "@/core/services/store/request.module";
export default {
  name: "UserPermissionList",
  props: {
    permissionDetail: {
      type: Object,
      default: function _default() {
        return new Object();
      }
    }
  },
  methods: {
    getModules: function getModules() {
      var _this = this;

      this.$store.dispatch(GET, {
        url: "/permission"
      }).then(function (_ref) {
        var data = _ref.data;
        _this.items = data;
      }).catch(function (error) {
        _this.logError(error);
      });
    }
  },
  mounted: function mounted() {
    this.getModules();
    this.permissionDet = this.permissionDetail;
  }
};